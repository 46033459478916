<template>
    <div>
        <van-nav-bar title="项目管理" left-text="返回" @click-left="back" :right-text="projectAdd?'新增项目':''" @click-right="openDialog(-1)" fixed left-arrow/>
        
        <div class="login_form">
            <van-sticky offset-top="1.22667rem">
            <!-- <van-cell> -->
                <div class="popupSearch">
                    <van-search
                    v-model="popoverValue"
                    show-action
                    shape="round" background="#F4F4F4"
                    :placeholder="'请输入' + (popoverType == '1' ? '项目名称' : user.companyId == '7030' ? '项目令号' : '项目编号') + '关键词'"
                    @search="popoverSearch"
                    >
                    <template slot="left">
                        <van-popover
                        v-model="popoverShow"
                        trigger="click"
                        :actions="popoverActions"
                        @select="popoverSelect"
                        placement="bottom-start"
                        >
                        <template slot="reference">
                            <span>{{popoverType == '1' ? '项目名称' : user.companyId == '7030' ? '项目令号' : '项目编号'}}<van-icon name="arrow-down" size="10" style="margin-left:2px;" /></span>
                        </template>
                        </van-popover>
                    </template>
                    <template slot="action">
                        <div @click="popoverSearch">搜索</div>
                    </template>
                    </van-search>
                </div>
            <!-- </van-cell> -->
            </van-sticky>
            <van-pull-refresh v-model="isDownLoading" @refresh="onDownRefresh">
                <van-list v-model="isUpLoading" :finished="upFinished" :immediate-check="false" :offset="100" finished-text="没有更多了" @load="onLoadList">
                    <!-- <van-swipe-cell v-for="(item,index) in list" :key="index">
                        <van-cell :border="false" :title="item.projectName" :value="item.projectCode" clickable @click="toProjectInside(item)"/>
                    </van-swipe-cell>  -->
                    <div v-for="(item, index) in list" :key="index" class="popupItem paddingDiv" @click="toProjectInside(item)">
                        <p class="popupItemOne" v-if="item.projectName" style="overflow: auto;white-space:normal;">{{item.projectName}}</p>
                        <p class="popupItemTwo" v-if="item.projectCode" style="overflow: auto;white-space:normal;">{{item.projectCode}}</p> 
                    </div>
                </van-list>
            </van-pull-refresh>

            <van-dialog v-model="show" :title="title" show-cancel-button :beforeClose="chargeBtn">
                <van-form style="margin: 0.4rem 0;">
                    <van-field v-model="form.projectCode" :name="user.companyId == '7030' ? '项目令号' : '项目编号'" :label="user.companyId == '7030' ? '项目令号' : '项目编号'" placeholder="请填写项目编号" />
                    <van-field v-model="form.projectName" name="项目名称" label="项目名称" placeholder="请填写项目名称" :rules="[{ required: true, message: '请填写项目名称' }]"/>
                    <van-field readonly clickable v-model="form.userNames"  label="参与人" 
                    placeholder="请选择参与人" @click="clickPicker()">
                        <template #input>
                            <span v-if="user.userNameNeedTranslate == '1'">
                                <span v-for="item,index in form.userNames.split(',')" :key="item">
                                    <TranslationOpenDataText type='userName' :openid='item'></TranslationOpenDataText>
                                    <span v-if="index != (form.userNames.split(',').length - 1)">,</span>
                                </span>
                            </span>
                            <span v-else>{{form.userNames}}</span>
                        </template>
                    </van-field>
                    <van-field readonly clickable  v-model="form.inchargerName"  label="项目经理" 
                    placeholder="请选择项目经理" @click="showPickerIncharger = true">
                        <template #input>
                            <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='form.inchargerName'></TranslationOpenDataText></span>
                            <span v-else>{{form.inchargerName}}</span>
                        </template>
                    </van-field>
                    <!-- 研究中心 -->
                    <van-field v-if="user.timeType.customDegreeActive == 1" readonly clickable name="userNames" v-model="form.associateDegreeNames"  label="研究中心" 
                    placeholder="请选择" @click="clikls()"/>
                </van-form>
            </van-dialog>

            <van-popup v-model="showPickerUser" position="bottom" ref="usersPopup" style="height: 90%">
                <!-- <van-search v-model="userName" placeholder="输入员工姓名搜索" @search="onSearch" v-if="user.userNameNeedTranslate != '1'"></van-search>
                <div style="minHeight:300px;">
                <template v-if="user.userNameNeedTranslate == '1'">
                    <van-checkbox class="userCheckbox" v-for="(item) in userList" :key="item.id" v-model="item.isChecked" ><TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText></van-checkbox>
                </template>
                <template v-else>
                    <van-checkbox class="userCheckbox" v-for="(item) in userList" :key="item.id" v-model="item.isChecked" >{{item.name}}</van-checkbox>
                </template>
                <van-button style="width:100%;position: -webkit-sticky;position: sticky;bottom: 0;" @click="refreshParticipate();showPickerUser=false">确定</van-button>
                </div> -->
                <div class="popupDiv">
                    <div class="popupSearch" v-if="user.userNameNeedTranslate != '1'">
                        <van-search v-model="userName" placeholder="输入员工姓名搜索" @search="onSearch" shape="round" background="#F4F4F4"></van-search>
                    </div>

                    <div class="popupCon conBorder">
                        <div class="popupConBox" v-if="user.userNameNeedTranslate == '1'">
                            <van-checkbox class="popupItem marginNone borderNone" v-for="(item) in userList" :key="item.id" v-model="item.isChecked" >
                                <span class="userNameClass_left">
                                    <TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText>
                                </span>
                                <span class="userNameClass_right">
                                    {{ item.jobNumber }}
                                </span>
                            </van-checkbox>
                        </div>
                        <div class="popupConBox" v-else>
                            <van-checkbox class="popupItem marginNone borderNone" v-for="(item) in userList" :key="item.id" v-model="item.isChecked" >
                                <span class="userNameClass_left">{{item.name}}</span>
                                <span class="userNameClass_right">{{ item.jobNumber }}</span>
                            </van-checkbox>
                        </div>
                    </div>

                    <div class="popupBtn">
                        <van-button style="width:100%;background: #1989fa;color: #ffffff;position: -webkit-sticky;position: sticky;bottom: 0;" round @click="refreshParticipate();showPickerUser=false;" >确定</van-button>
                    </div>
                </div>
            </van-popup>

            <van-popup v-model="showPickerIncharger" position="bottom">
                <van-picker show-toolbar :columns="inchargerUserList" value-key="name" @confirm="choseIncharger" @cancel="showPickerIncharger = false">
                    <template #option="item">
                        <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText></span>
                        <span v-else>{{item.name}}</span>
                    </template>
                </van-picker>
            </van-popup>
            <!-- 研究中心 -->
            <van-popup v-model="cliklss" position="bottom">
                <div style="minHeight:300px;">
                <van-checkbox class="userCheckbox" v-for="(item) in ause" :key="item.id" v-model="item.isChecked" @change="fuxuan()" @click="ddd()">{{item.name}}</van-checkbox>
                <van-button style="width:100%;" @click="refreshParticipatess();cliklss=false">确定</van-button>
                </div>
            </van-popup>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                canEdit:false,
                user: JSON.parse(localStorage.userInfo),
                showPickerUser: false,
                showPickerIncharger: false,
                userName:null,
                total: 0,
                page: 1,
                size: 1000,
                list: [],
                loading: false,
                finished: false,
                error: false,
                refreshing: false,
                allUserList:[],
                userList:[],
                inchargerUserList:[],
                show: false,
                title: "修改项目",
                form: {
                    id: null,
                    projectName: "",
                },
                cliklss: false,
                ause: [],
                projectAdd: false, // 新增项目
                projectManagement: false, // 管理项目
                isDownLoading: false, // 下拉刷新
                isUpLoading: false, // 上拉加载
                upFinished: false, // 上拉加载完毕
                offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
                pageSize: 25, // 每页条数
                pageIndex: 1, // 页码
                dpp: false,

                popoverValue: '',
                popoverShow: false,
                popoverType: '1',
                popoverActions: [{text: '项目名称',type: '1'},{text: JSON.parse(localStorage.userInfo).companyId == '7030' ? '项目令号' : '项目编号',type: '2'}],
                projectVue: null,
            };
        },
        created() {
        },
        methods: {
            toProjectInside(item){
                if(this.user.company.packageProject == 1){
                    sessionStorage.setItem('projectId',JSON.stringify(item.id))
                    this.$router.push("/projectInside");
                }
                if(this.user.company.packageWorktime == 1) {
                    sessionStorage.setItem('projectId',JSON.stringify(item.id))
                    this.$router.push("/projectInside");
                }
                
            },
            popoverSelect(action){
                if(this.popoverType == action.type){
                    return
                }else{
                    this.popoverType = action.type
                    this.popoverValue = ''
                    // this.isDownLoading = true
                    this.list = []
                    this.isUpLoading = true
                    this.onDownRefresh()
                }
            },
            popoverSearch(){
                // this.isDownLoading = true
                this.list = []
                this.isUpLoading = true
                this.onDownRefresh()
            },
            fuxuan() {
                console.log(this.ause)
            },
            ddd() {
                let aes = this.ause
                this.ause = aes 
                console.log(this.ause, '12345')
            },
            yanjiuzx() {
                this.$axios.post("/report-extra-degree/getAll", {})
                .then(res => {
                    if(res.code == "ok") {
                        for(var i in res.data) {
                            res.data[i].isChecked = false
                        }
                        this.ause = res.data
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err=> {this.$toast.clear();});
            },
            choseIncharger(value, index) {
                this.showPickerIncharger = false;
                this.form.inchargerName = value.name;
                this.form.inchargerId = value.id;
            },
            onSearch(val) {
                console.log(val);
                this.userList = [];
                this.allUserList.forEach(u=>{if (u.name.startsWith(val)) {
                    this.userList.push(u);
                }})
                
            },
            //刷新参与人
            refreshParticipate() {
                var that = this;
                that.inchargerUserList = [];
                that.form.userId = [];
                let userNames = ''
                this.userList.filter(u=>u.isChecked).forEach(u=>{
                    userNames+=(u.name+',');
                    that.form.userId.push(u.id);
                    that.inchargerUserList.push(u);
                });
                // this.form.userNames = userNames
                if (userNames.length > 0) {
                    userNames = userNames.substring(0, userNames.length-1);
                }
                this.$set(this.form,'userNames',userNames)
            },
            // 刷新研究中心
            refreshParticipatess() {
                var auseNme = []
                var auseList = []
                for(var i in this.ause) {
                    if(this.ause[i].isChecked) {
                        if(this.ause[i].isChecked == true) {
                            auseNme.push(this.ause[i].name)
                            auseList.push(this.ause[i].id)
                        }
                    }
                }
                this.form.associateDegreeNames = auseNme.toString()
                this.form.associateDegrees = auseList
                console.log(this.form, '刷新')
            },
            onChange() {
                console.log('===');
            },
            choseUsers() {

            },
            clickPicker() {
                this.showPickerUser = true;
            },
            clikls() {
                this.cliklss = true
                for(var i in this.ause) {
                    if(this.form.associateDegrees.length > 0) {
                        for(var j in this.form.associateDegrees) {
                            if(this.form.associateDegrees[j] == this.ause[i].id) {
                                this.ause[i].isChecked = true
                            }
                        }
                    }
                }
                this.ause = this.ause
                console.log(this.ause)
            },
            // 返回
            back() {
                history.back();
            },
            //获取用户列表
            getUsers() {
                this.$axios.post("/user/getEmployeeList", {
                    departmentId: -1,
                    pageIndex: 1,
                    // pageSize: 99999
                    pageSize: -1
                })
                .then(res => {
                    if(res.code == "ok") {
                        this.loading = false;
                        var sj = res.data.records
                        for (var i in sj) {
                            sj[i].isChecked = false
                        }
                        // this.userList = res.data.records;
                        this.userList = sj;
                        this.allUserList = res.data.records;
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err=> {this.$toast.clear();});
            },

            // 获取项目
            getProject() {
                    let parameter = {
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                    }
                    if(this.popoverValue){
                        parameter.keyword = this.popoverValue
                        parameter.searchField = this.popoverType
                    }
                this.$axios.post("/project/getProjectPage", parameter)
                .then(res => {
                    if(res.code == "ok") {
                        this.isDownLoading = false
                        this.isUpLoading = false
                        this.total = res.data.total
                        var datas = res.data.records
                        if(res.data.records.length <= 0) {
                            this.total = 0,
                            this.list = []
                            this.$toast('已全部加载完成');
                            return
                        } else {
                            // console.log(this.dpp)
                            if(this.dpp) this.list = []
                            if(this.list.length == 0) {
                                this.list = datas
                            } else {
                                for(var i in datas) {
                                    this.list.push(datas[i])
                                }
                            }
                        }
                    } else {
                        this.$toast.fail('获取失败');
                        this.isDownLoading = false
                        this.isUpLoading = false
                    }
                }).catch(err=> {
                    this.$toast.clear();
                    this.isDownLoading = false
                    this.isUpLoading = false
                });
            },
            onDownRefresh() {
                // console.log(123)
                this.dpp = true
                this.pageIndex = 1
                this.upFinished = false // 不写这句会导致你上拉到底过后在下拉刷新将不能触发下拉加载事件
                this.getProject()
            },
            onLoadList() {
                if(this.total <= this.list.length) {
                    this.upFinished = true
                    this.isUpLoading = false
                    return
                }
                this.pageIndex = +this.pageIndex + 1
                this.dpp = false
                this.getProject()
            },

            // onRefresh() {
            //     this.finished = false;
            //     this.loading = true;
            //     this.page = 1;
            //     this.getProject();
            // },

            // 新增、编辑项目
            openDialog(i) {
                if(i == -1) {
                    this.title = "新增项目";
                    this.form = {
                        id: null,
                        projectName: "",
                        projectCode:null,
                        inchargerName:null,
                        inchargerId:null,
                        userId:null,
                        userNames:'',
                        associateDegreeNames: null,
                        associateDegrees: null
                    }
                } else {
                    this.title = "修改项目";
                    var arrs
                    if(this.list[i].associateDegrees) {
                        arrs = this.list[i].associateDegrees.split(',')
                        for(var j in arrs) {
                            arrs[j] = +arrs[j] + 0
                        }
                    } else {
                        arrs = []
                    }
                    var name
                    if(this.list[i].associateDegreeNames != null && this.list[i].associateDegreeNames != 'null') {
                        name = this.list[i].associateDegreeNames
                    } else {
                        name = ''
                    }
                    this.form = {
                        id: this.list[i].id,
                        projectName: this.list[i].projectName,
                        projectCode: this.list[i].projectCode,
                        inchargerId: this.list[i].inchargerId,
                        inchargerName: this.list[i].inchargerName,
                        associateDegreeNames: name,
                        associateDegrees: arrs
                    }
                    var part = this.list[i].participator;
                    if (part.length>0) {
                        for (var j in part) {
                            this.userList.filter(u=>u.id == part[j].id)[0].isChecked = true;
                        }
                        this.refreshParticipate();
                    }
                }
                this.show = true;
            },

            chargeBtn(action, done) {
                if (action === 'confirm') {
                    this.show = false;
                    const toast = this.$toast.loading({
                        forbidClick: true,
                        duration: 0
                    });
                    let formData = new URLSearchParams();
                    formData.append("name", this.form.projectName);
                    formData.append("code", this.form.projectCode);
                    if (this.form.inchargerId) {
                        formData.append("inchargerId", this.form.inchargerId);
                    }
                    
                    for (var j in this.form.userId) {
                        formData.append("userId", this.form.userId[j]);
                    }
                    if(this.form.id != null) {
                        formData.append("id", this.form.id);
                    }
                    if(this.form.associateDegreeNames) {
                        formData.append("associateDegreeNames", this.form.associateDegreeNames);
                    } 

                    if(this.form.associateDegrees) {
                        var sss = this.form.associateDegrees.toString()
                        formData.append("associateDegrees", sss);
                    }

                    formData.append("isPublic", 0);

                    this.$axios.post("/project/editProject", formData)
                    .then(res => {
                        if(res.code == "ok") {    
                            this.$toast.clear();
                            this.$toast.success(this.form.id==null?'新增成功':'修改成功');
                            // this.list = [];
                            // this.page = 1;
                            this.onDownRefresh();
                            // this.getProject();
                            this.yanjiuzx()
                        } else {
                            this.$toast.clear();
                            this.$toast.fail(res.msg);
                        }
                    }).catch(err=> {this.$toast.clear();});
                } else {
                    this.show = false;
                    this.yanjiuzx()
                }
                done();
            },

            // 删除项目
            delPro(i) {
                this.$dialog.confirm({
                    title: '删除项目',
                    message: '确定要项目'+this.list[i].projectName+'吗?'
                }).then(() => {
                    const toast = this.$toast.loading({
                        forbidClick: true,
                        duration: 0
                    });
                    this.$axios.post("/project/deleteProject", {id: this.list[i].id})
                    .then(res => {
                        if(res.code == "ok") {
                            this.$toast.clear();
                            this.$toast.success('删除成功');
                            this.list = [];
                            this.page = 1;
                            this.getProject();
                        } else {
                            this.$toast.clear();
                            this.$toast.fail(res.msg);
                        }
                    }).catch(err=> {this.$toast.clear();});
                }).catch(() => {});
            },

        },

        mounted() {
            this.getUsers();
            this.yanjiuzx()
            this.getProject()
            // this.canEdit = (this.user.role==1||this.user.role==2||this.user.role==5);
            var list = this.user.functionList
            for(var i in list) {
                if(list[i].name == '新增项目') {
                    this.projectAdd = true
                }
                if(list[i].name == '管理全部项目') {
                    this.projectManagement = true
                }
            }
        }
    };
</script>

<style lang="less" scoped>
    .login_form {
        margin-top: 46px;
    }

    .one_report {
        margin-bottom: 15px;
    }

    .form_text {
        margin: 15px 0 30px;
        padding: 0 12px;
    }
    
    .form_btn {
        text-align: right;
    }

    .form_btn button {
        margin-left: 10px;
    }

    .one_report_data {
        margin-bottom: 20px;
        padding: 0 22px;
        div {
            line-height: 30px;
        }
    }
    .userCheckbox {
        padding: 10px;;
    }
</style>
<style lang="less">
    .van-nav-bar .van-icon , .van-nav-bar__text {
        color: #20a0ff;
    }
</style>
